import './abbove-style.scss';
import { ReactComponent as AbboveIcon } from '../../assets/icons/icon-abbove.svg';

const ButtonImportAbbove = ({ onImport }) => {

  const acceptExtension = ["enc", "pgp", "gpg", "txt", "asc"];

  const handleButtonClick = () => {
    const fileInputAbbove = document.getElementById('file-input-abbove');
    if (fileInputAbbove) {
      fileInputAbbove.click();
    }
  };

  const getFileExtension = (filename) => {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  }

  const handleFileChange = (event) => {

    const file = event.target.files[0];

    if (file) {
      // Check if the file type is JSON
      if (acceptExtension.includes(getFileExtension(file.name))) {
        onImport(file);
        // const reader = new FileReader();

        // reader.onload = (e) => {
        //   try {
        //     // const jsonData = JSON.parse(e.target.result);
        //     onImport(file);
        //   } catch (error) {
        //     console.error('Error parsing JSON file:', error);
        //   }
        // };

        // reader.readAsText(file);
      } else {
        console.error('Invalid file type. Please select a JSON file.');
      }
    }
  };


  return (
    <div>
      <input
        type="file"
        accept=".enc,.pgp,.gpg,.txt"
        id="file-input-abbove"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <button className="abbove-fixed-right-bottom-button" onClick={handleButtonClick}>
        <AbboveIcon />
      </button>
    </div>
  );
}

export default ButtonImportAbbove;
